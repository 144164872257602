import React, { Component } from 'react';

import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Head from 'components/head';
import HeaderImage from 'components/header/header-image/headerImage.js';
import Sidebar from 'components/sidebar';
import { is } from 'core-js/fn/object';

export default class OfferPage extends Component {
  // const { pathname } = props.location;
  // let n = pathname.lastIndexOf('/');
  // let pageId = pathname.substring(n + 1);
  // const ulScrollRestoration = useScrollRestoration(`page-component-ul-list`)
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      active: '',
    };
  }
  nameAttPicker(e) {
    const name = e.target.dataset.name;
    this.setState({
      name: e.target.dataset.name,
    });
  }

  renderItems(name) {
    switch (name) {
      case '':
        return (
          <div className="offer-item">
            <div className="offer-item__title">
              <div className="offer-item__icon">
                <img src="/pictures/002-hotel.svg" alt="ikona nieruchomości" />
              </div>
              <h3>NIERUCHOMOŚCI</h3>
            </div>
            <p className="mt-4">
              {' '}
              Zajmujemy się wszelkimi sprawami z zakresu{' '}
              obrotu nieruchomościami, nie tylko z terenu Warszawy i okolic ale także z pozostałych części kraju. W tym <strong>wspieramy naszych
              klientów przy zakupie, sprzedaży, najmie, administrowaniu i
              zarządzaniu nieruchomościami, a także przy porządkowaniu stanu
              prawnego oraz doradzamy w zakresie rozwiązań prawnych przy
              rozporządzaniu na wypadek zdarzeń losowych.</strong> {' '}
            </p>
            <p>
              Reprezentujemy osoby fizyczne i prawne w
              postępowaniu dot. przekształcenia prawa użytkowania wieczystego w
              prawo własności. W Warszawie zapewniamy bieżącą obsługę prawną dla wspólnot
              mieszkaniowych i administratorów/zarządców oraz agencji
              pośrednictwa w obrocie nieruchomościami.
            </p>
          </div>
        );
      case 'nieruchomosci':
        return (
          <div className="offer-item">
            <div className="offer-item__title">
              <div className="offer-item__icon">
                <img src="/pictures/002-hotel.svg" alt="ikona nieruchomości" />
              </div>
              <h3>NIERUCHOMOŚCI</h3>
            </div>
            <p className="mt-4">
              {' '}
              Zajmujemy się wszelkimi sprawami z zakresu{' '}
              obrotu nieruchomościami, nie tylko z terenu Warszawy i okolic ale także z pozostałych części kraju. W tym <strong>wspieramy naszych
              klientów przy zakupie, sprzedaży, najmie, administrowaniu i
              zarządzaniu nieruchomościami, a także przy porządkowaniu stanu
              prawnego oraz doradzamy w zakresie rozwiązań prawnych przy
              rozporządzaniu na wypadek zdarzeń losowych.</strong> {' '}
            </p>
            <p>
              Reprezentujemy osoby fizyczne i prawne w
              postępowaniu dot. przekształcenia prawa użytkowania wieczystego w
              prawo własności. W Warszawie zapewniamy bieżącą obsługę prawną dla wspólnot
              mieszkaniowych i administratorów/zarządców oraz agencji
              pośrednictwa w obrocie nieruchomościami.
            </p>
          </div>
        );
      case 'frankowe':
        return (
          <div className="offer-item">
            <div className="offer-item__title">
              <div className="offer-item__icon">
                <img
                  src="/pictures/007-loan.svg"
                  alt="ikona powództwa frankowe"
                />
              </div>
              <h3>POWOÓDZTWA FRANKOWE</h3>
            </div>
            <p className="mt-4">
              {' '}
              Świadczymy doradztwo prawne w zakresie{' '}
              umów kredytów frankowych. Reprezentujemy
              konsumentów w sporach sądowych z bankami w ramach tzw. powództw
              frankowych, zarówno w zakresie unieważniania umów jak i ich tzw.
              odfrankowienia.{' '}
            </p>
            <p>
              <strong>
                Doradzamy w zakresie możliwych roszczeń wynikających z zawarcia
                wadliwych umów kredytów denominowanych lub indeksowanych do
                waluty obcej. Ze względu na siedziby banków spory toczą się w Warszawie. Prowadzimy również spory sądowe </strong> dotyczące ubezpieczenie niskiego
              wkładu własnego. Zajmujemy się także kredytami spłaconymi i
              wypowiedzianymi umowami kredytów.
            </p>
          </div>
        );
      case 'cywilne':
        return (
          <div className="offer-item">
            <div className="offer-item__title">
              <div className="offer-item__icon">
                <img
                  src="/pictures/003-auction.svg"
                  alt="ikona prawa cywilnego"
                />
              </div>
              <h3>PRAWO CYWILNE I SPADKOWE</h3>
            </div>
            <p className="mt-4">
              {' '}
              Reprezentujemy osoby fizyczne w postępowaniach{' '}
              mediacyjnych oraz sporach sądowych z zakresu
              prawa cywilnego przed sądami w Warszawie i poza nią. W szczególności <strong> w zakresie prawa{' '}
              spadkowego</strong> , zarówno w postępowaniach w
              przedmiocie ustalenia spadkobierców, praw do spadku jak i o dział
              spadku.{' '}
            </p>
            <p>
              Zajmujemy się sporami o <strong>zachowek,</strong>  uznaniem za niegodnego
              dziedziczenia jak i wydziedziczeniami. Analizujemy sytuacje prawne
              pod kątem <strong>przyjęcia bądź odrzucenia spadku oraz wyboru formy i
              treści testamentów.</strong>  Przygotowujemy umowy dot. spadku (zrzeczenie
              dziedziczenia, zbycie spadku).
            </p>
            <p>
              Doradzamy klientom i prowadzimy  <strong> spory sądowe dotyczące umów{' '}
              dożywocia, darowizny i renty</strong>. Prowadzimy
              postępowania o <strong>zasiedzenie</strong>  i zniesienie współwłasności i in.
              Prowadzimy spory w przypadku <strong>nienależytego wykonania bądź
              niewykonywania umowy.</strong>  Reprezentujemy <strong>  w sprawach o zapłatę,
              postępowaniu  egzekucyjnym i windykacyjnym.</strong>
            </p>
          </div>
        );
      case 'rodzinne':
        return (
          <div className="offer-item">
            <div className="offer-item__title">
              <div className="offer-item__icon">
                <img src="/pictures/005-family.svg" alt="" />
              </div>
              <h3>PRAWO RODZINNE</h3>
            </div>
            <p className="mt-4">
              {' '}
              Wspieramy osoby fizyczne w 
                sporach rodzinnych.
             {' '}
              Świadczymy usługi w Warszawie, okolicach (Mazowsze) ale także i innych rejonach kraju.
              Zarówno w postępowaniu o <strong> rozwód jak i o separację czy podział
              majątku. </strong> Ponadto zajmujemy się sprawami <strong> o ustalenie kontaktów
              rodzica z dzieckiem jak i o  alimenty</strong>.{' '}
            </p>
            <p>
              Prowadzimy sprawy o ograniczenie lub pozbawienie władzy
              rodzicielskiej. Reprezentujemy przedstawicieli ustawowych
              (rodziców, opiekunów) w sprawach małoletnich i
              ubezwłasnowolnionych. Występujemy o zgody sądu na dokonanie
              czynności w imieniu małoletnich i ubezwłasnowolnionych (m.in.
              sprzedaż nieruchomości i in). Prowadzimy  <strong> sprawy o
             ubezwłasnowolnienia </strong>  częściowe lub całkowite oraz
              ustanowienie kuratora lub opiekuna.
            </p>
          </div>
        );
      case 'pracy':
        return (
          <div className="offer-item">
            <div className="offer-item__title">
              <div className="offer-item__icon">
                <img src="/pictures/004-suitcase.svg" alt="ikona prawa pracy" />
              </div>
              <h3>PRAWO PRACY</h3>
            </div>
            <p className="mt-4">
              {' '}
              Pomagamy pracownikom w sporach z pracodawcami z terenu całej Polski, w tym Warszawy i Mazowsza.
              Zarówno w <strong> zakresie kwestionowania wypowiedzenia warunków pracy i
              płacy jak i wypowiedzeń umów o pracę czy rozwiązywania umów bez
              zachowania okresów wypowiedzenia (tzw. dyscyplinarki).</strong> Ponadto
              zajmujemy się postępowaniami w zakresie{' '}
              prostowania świadectw pracy. Wspieramy
              pracowników w postępowaniach o <strong>mobbing</strong> czy postępowaniach
              odszkodowawczych.{' '}
            </p>
            <p>
              Prowadzimy spraw w zakresie ustalania{' '}
              istnienia stosunku pracy (umowy cywilnoprawne na
              umowy o pracę) oraz <strong> spory na gruncie umów zlecenia i o dzieło.</strong>
            </p>
          </div>
        );
      case 'gospodarcze':
        return (
          <div className="offer-item">
            <div className="offer-item__title">
              <div className="offer-item__icon">
                <img
                  src="/pictures/006-fluctuation.svg"
                  alt="ikona prawa gospodarczego"
                />
              </div>
              <h3>PRAWO GOSPODARCZE</h3>
            </div>
            <p className="mt-4">
              {' '}
              Doradzamy mały i średnim przedsiębiorcom z Warszawy i okolic ale także zapewniamy pomoc podmiotom z poza stolicy.{' '}
              <strong>
                Zapewniamy obsługę prawną przy zakładaniu, przekształcaniu i
                prowadzeniu wszelkich form działalności gospodarczej </strong> 
                (jednoosobowa działalność, spółka cywilna, spółki prawa
                handlowego, spółdzielnie, fundacje i stowarzyszenia)
              {' '}
              . Oferujemy pomoc prawną w sporach z kontrahentami.{' '}
            </p>
            <p>
              <strong>Prowadzimy negocjacje przy zawieraniu umów</strong>  oraz prowadzimy
              mediacje i spory sądowe w przypadku ich niewykonywania bądź
              nienależytego wykonania.
            </p>
            <p>
              <strong>Opracowujemy wzory umów </strong> (w tym umowy spółki i statuty, zlecenia, o
              dzieło, roboty budowlane, najmu i in.), porozumień i ugód.
              <strong> Przeprowadzamy{' '}
              postępowania likwidacyjne i upadłościowe</strong> , w tym
              <strong> upadłość konsumencką.</strong> Prowadzimy wszelkie postępowania przed
              Krajowym Rejestrem Sądowym i sądami gospodarczymi. Reprezentujemy
              przedsiębiorców w sprawa <strong>o zapłatę, postępowaniu egzekucyjnym i
              windykacyjnym.</strong> 
            </p>
          </div>
        );
      case 'administracyjne':
        return (
          <div className="offer-item">
            <div className="offer-item__title">
              <div className="offer-item__icon">
                <img
                  src="/pictures/003-auction.svg"
                  alt="ikona prawa admninistracyjnego"
                />
              </div>
              <h3>PRAWO ADMINISTRACYJNE</h3>
            </div>
            <p className="mt-4">
              {' '}
              Oferujemy <strong> wsparcie zarówno osobom{' '}
              fizycznym jak i instytucjom publicznym</strong>, w
              zakresie prawa administracyjnego (m.in. prawo budowlane, ustawa o
              gospodarce nieruchomościami, prawa użytkowania wieczystego i in.)
              jak i    <strong> postępowania{' '}
            administracyjnego oraz sądowoadministracyjnego</strong>.{' '}
            </p>
            <p>
              Reprezentujemy osoby fizyczne przed organami (w tym samorządowymi
              kolegiami odwoławczymi) i sądami administracyjnymi oraz instytucje
              w sporach z obywatelami. Inicjujemy postępowania w przedmiocie
              wznowienia postępowania czy stwierdzenia nieważności decyzji.{' '}
                Doradzamy w zakresie dostępu do informacji publicznej{' '}
              i ustaw samorządowych. Usługi świadczymy na rzecz podmiotów z Warszawy i pozostałych części Polski.
            </p>
          </div>
        );
      case 'autorskie':
        return (
          <div className="offer-item">
            <div className="offer-item__title">
              <div className="offer-item__icon">
                <img
                  src="/pictures/008-auction-1.svg"
                  alt="ikona prawa autorskiego"
                />
              </div>
              <h3>PRAWO AUTORSKIE</h3>
            </div>
            <p className="mt-4">
              {' '}
              Twórcy oraz korzystający z utworów mogą uzyskać fachowe wsparcie w
              zakresie prawa autorskiego. Reprezentujemy zarówno osoby fizyczne
              (artystów) jak i prawne oraz instytucje publiczne czy
              stowarzyszenia i fundacje.{' '}
            </p>
            <p>
              <strong>Opracowujemy projekty umów </strong>  dot. korzystania z
              utworów jak również prowadzimy <strong> postępowania sądowe w przypadku
              naruszenia praw autorskich. </strong> Zajmujemy się sprawami <strong>z zakresu{' '}
              ochrony dóbr osobistych i wizerunku,</strong>  a także
              zagadnieniami <strong> prawa mediów i reklamy.</strong> Ponadto oferujemy doradztwo
              w zakresie <strong> prawa prasowego.</strong> Współpracuje z klientami z Warszawy, okolicy i pozostałych regionów Polski.
            </p>
          </div>
        );
    }
  }
  render() {
    return (
      <Layout buttonClass="white">
        <HeaderImage title="USŁUGI" />
        <section className="offerPage">
          <div className="container">
            <div className="row">
              <div className="offerPage__headline text-left">
                <h3 className="">OBSZAR NASZEJ DZIAŁALNOŚCI</h3>
              </div>
              <div id="" className="offerPage__content mt-8">
                <div id="" className="offerPage__text">
                  {this.renderItems(this.state.name)}
                </div>
                <div className="offerPage__sidebar">
                  <div className="sidebar">
                    <ul>
                      <span onClick={this.nameAttPicker.bind(this)}>
                        <li
                          className={
                            this.state.name == 'nieruchomosci' ? 'active' : ''
                          }
                          data-name="nieruchomosci"
                        >
                          NIERUCHOMOŚCI
                        </li>
                      </span>
                      <span onClick={this.nameAttPicker.bind(this)}>
                        <li
                          className={
                            this.state.name == 'frankowe' ? 'active' : ''
                          }
                          data-name="frankowe"
                        >
                          POWÓDZTWA FRANKOWE
                        </li>
                      </span>
                      <span onClick={this.nameAttPicker.bind(this)}>
                        <li
                          className={
                            this.state.name == 'cywilne' ? 'active' : ''
                          }
                          data-name="cywilne"
                        >
                          PRAWO CYWILNE I SPADKOWE
                        </li>
                      </span>
                      <span onClick={this.nameAttPicker.bind(this)}>
                        <li
                          className={
                            this.state.name == 'rodzinne' ? 'active' : ''
                          }
                          data-name="rodzinne"
                        >
                          PRAWO RODZINNE
                        </li>
                      </span>
                      <span onClick={this.nameAttPicker.bind(this)}>
                        <li
                          className={this.state.name == 'pracy' ? 'active' : ''}
                          data-name="pracy"
                        >
                          PRAWO PRACY
                        </li>
                      </span>
                      <span onClick={this.nameAttPicker.bind(this)}>
                        <li
                          className={
                            this.state.name == 'gospodarcze' ? 'active' : ''
                          }
                          data-name="gospodarcze"
                        >
                          PRAWO GOSPODARCZE
                        </li>
                      </span>
                      <span onClick={this.nameAttPicker.bind(this)}>
                        <li
                          className={
                            this.state.name == 'administracyjne' ? 'active' : ''
                          }
                          data-name="administracyjne"
                        >
                          PRAWO ADMINISTRACYJNE
                        </li>
                      </span>
                      <span onClick={this.nameAttPicker.bind(this)}>
                        <li
                          className={
                            this.state.name == 'autorskie' ? 'active' : ''
                          }
                          data-name="autorskie"
                        >
                          PRAWO AUTORSKIE
                        </li>
                      </span>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
