import React from 'react';
import Success from '../../../images/images-js/success';
const HeaderImage = props => {
  return (
    <div className="suit-image">
      <div className="container-fluid">
        <div className="row">
          <div className="header__image">
            <Success />
  <h1 className="header-image__title">{props.title}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderImage;
